import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LinesEllipsis from 'react-lines-ellipsis'

const CategoryLink = ({ category }) => {

  return (
    <div className="box">
      <div className="columns ">
        { category.image &&
            <div className="column">
              <GatsbyImage
                image={getImage(category.image.localFile)}
                alt={category.name}
              />
            </div>
        }
        <div className="column">
          <div className="content">
            <h3 className="title is-3">
              {category.name}
            </h3>
              <LinesEllipsis
                text={category.description}
                maxLine='3'
                className="mb-3"
              />
            <Link
              to={category.slug}
              className="button is-info">
              Meer over {category.name}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryLink
