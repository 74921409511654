import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'
import CategoryLink from '../components/CategoryLink'

const IndexPage = ({ data }) => {
  const hero = data.strapiHomepage.hero

  const CategoriesRow = data.allStrapiCategory.edges.map(edge =>
    <CategoryLink key={edge.node.id} category={edge.node} />
  )

  return (
    <Layout>
      <Seo />
      <Hero title={ hero.title } subtitle={ hero.subtitle }/>
      <section className="section has-background-white-ter">
        <div className="container">
          {CategoriesRow}
        </div>
      </section>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allStrapiCategory(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          id
          name
          description
          slug
          created_at
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 640
                  height: 300
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
        }
      }
    }
    strapiHomepage {
      hero {
        title
        subtitle
        background {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
